// breakpoints
$grid-breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
  );

// mixins
@mixin mediaqueries($brk) {
  @media (min-width: $brk) {
    @content;
  }
}